export const apiURL =
  process.env.REACT_APP_API_URL ||
  'http://staging.api.restwithdignity.com:3000/api/v1/';
export const nodeEnv = process.env.NODE_ENV || 'development';
export const appURL = process.env.REACT_APP_APP_URL || 'http://localhost:3000';
export const stripeApiKey = process.env.REACT_APP_STRIP_API_KEY || 'stripeKey';
export const pricing30USD = process.env.REACT_APP_STRIPE_30_USD || '30';
export const pricing50USD = process.env.REACT_APP_STRIPE_50_USD || '50';
export const branchIOKey = process.env.REACT_APP_BRANCH_IO_KEY;
export const branchIOSecret = process.env.REACT_APP_BRANCH_IO_SECRET;
export const gaTrackingId =
  process.env.REACT_APP_GA_TRACKING_ID || 'UA-83552742-1';
export const googleAppId = process.env.REACT_APP_GOOGLE_APP_ID || '';
export const fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID || '';
