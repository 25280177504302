import {
  unstable_createMuiStrictModeTheme as createMuiTheme, // TEMP: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
  // createMuiTheme,
} from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import 'typeface-poppins';
import 'typeface-roboto-slab';
import 'typeface-montserrat';

const appTheme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
    },
    h2: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
    },
    h3: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
    },
    h4: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
    },
    h5: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
    },
    h6: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
    },
  },
  palette: {
    primary: {
      main: grey[200],
    },
    secondary: {
      main: '#29A4FF',
      light: '#E5F4FF',
      dark: '#496995',
    },
    text: {
      primary: '#333333',
      secondary: '#29A4FF',
    },
    background: {
      default: grey[100],
    },
  },
});

export default appTheme;
