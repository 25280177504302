import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import memorialReducer from './memorial';
import uploadsReducer from './uploads';
import authReducer from './auth';
import editReducer from './edit';
import userReducer from './user';
import purchaseReducer from './purchase';
import availabilityReducer from './availability';
import condolenceReducer from './condolence';
import invitesReducer from './invites';
import cookiesReducer from './cookies';

const authPersistConfig = {
  key: 'rwd',
  storage: storage,
  blacklist: ['error', 'loading', 'user'],
};

const cookiesAgreementPersistConfig = {
  key: 'rwdCookies',
  storage: storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  memorial: memorialReducer,
  uploads: uploadsReducer,
  edit: editReducer,
  user: userReducer,
  purchase: purchaseReducer,
  availability: availabilityReducer,
  condolence: condolenceReducer,
  invites: invitesReducer,
  cookies: persistReducer(cookiesAgreementPersistConfig, cookiesReducer),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
