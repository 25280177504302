import { createSlice } from '@reduxjs/toolkit';
import rwdApi from 'services/rwdApi';
import { AppThunk } from 'store';

type InvitesState = {
  saving: boolean;
  error?: string;
};

const initialState: InvitesState = {
  saving: false,
};

const invites = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    saveInvitesStart: (state) => {
      state.saving = true;
      state.error = undefined;
    },
    saveInvitesFailed: (state, { payload }) => {
      state.saving = false;
      state.error = payload;
    },
    saveInvitesSuccess: (state) => {
      state.saving = false;
      state.error = undefined;
    },
  },
});

export const {
  saveInvitesStart,
  saveInvitesFailed,
  saveInvitesSuccess,
} = invites.actions;

export default invites.reducer;

export const sendInvites = (
  memorialUid: string,
  data: string[]
): AppThunk => async (dispatch) => {
  dispatch(saveInvitesStart());
  const { status, message } = await rwdApi.sendInvites(memorialUid, data);

  if (status === 'ok') {
    dispatch(saveInvitesSuccess());
  } else {
    dispatch(saveInvitesFailed(message));
  }
};
