import { createSlice } from '@reduxjs/toolkit';
import rwdApi from 'services/rwdApi';
import { AppThunk } from 'store';
import { User } from 'types';

type UserState = {
  data?: User;
  fetching: boolean;
  error?: string;
};

const initialState: UserState = {
  data: undefined,
  fetching: false,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserStart: (state) => {
      state.fetching = true;
      state.error = undefined;
    },
    fetchUserFailed: (state, { payload }) => {
      state.fetching = false;
      state.error = payload;
      state.data = undefined;
    },
    setUser: (state, { payload }) => {
      state.fetching = false;
      state.error = undefined;
      state.data = payload;
    },
  },
});

export const { fetchUserStart, fetchUserFailed, setUser } = user.actions;

export default user.reducer;

export const fetchUser = (): AppThunk => async (dispatch) => {
  dispatch(fetchUserStart());
  const { status, message, user } = await rwdApi.getUserDetails();

  if (status === 'ok') {
    dispatch(setUser(user));
  } else {
    dispatch(fetchUserFailed(message));
  }
};
