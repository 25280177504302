import { createSlice } from '@reduxjs/toolkit';

type CookiesState = {
  hasAgreed?: boolean;
};

const initialState: CookiesState = {
  hasAgreed: false,
};

const cookiesPolicy = createSlice({
  name: 'cookiesPolicy',
  initialState,
  reducers: {
    setHasAgreedToCookiesPolicy: (state) => {
      state.hasAgreed = true;
    },
  },
});

export const { setHasAgreedToCookiesPolicy } = cookiesPolicy.actions;

export default cookiesPolicy.reducer;
