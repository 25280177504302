import api from 'services/rwdApi';
import { RootState } from '../reducers';
import { Middleware } from 'redux';

export const authMiddleware: Middleware<RootState> = (store) => (next) => (
  action
) => {
  const state = store.getState();
  const { accessKey } = state.auth;

  if (accessKey) {
    // insert token from store to axios request
    api.updateRequestInterceptor(accessKey);
  }

  return next(action);
};

export default authMiddleware;
