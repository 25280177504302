import { createSlice } from '@reduxjs/toolkit';
import rwdApi from 'services/rwdApi';
import { AppThunk } from 'store';

type AvailabilityState = {
  availability?: boolean;
  fetching: boolean;
  error?: string;
};

const initialState: AvailabilityState = {
  fetching: false,
};

const purchase = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    checkStart: (state) => {
      state.fetching = true;
      state.error = undefined;
      state.availability = undefined;
    },
    checkFailed: (state, { payload }) => {
      state.fetching = false;
      state.error = payload;
      state.availability = undefined;
    },
    checkSuccess: (state, { payload: { availability } }) => {
      state.fetching = false;
      state.error = undefined;
      state.availability = availability;
    },
  },
});

export const { checkStart, checkFailed, checkSuccess } = purchase.actions;

export default purchase.reducer;

// Checks both pageLink availability and coupon code validity
export const checkAvailability = ({
  pageLink,
}: {
  pageLink: string;
}): AppThunk => async (dispatch) => {
  dispatch(checkStart());
  const availabilityResponse = await rwdApi.checkAvailability(pageLink);
  const { status } = availabilityResponse;
  const { availability } = availabilityResponse;
  const error = !availability && 'Page link taken.';

  if (status === 'ok') {
    dispatch(
      checkSuccess({
        availability,
      })
    );
  } else {
    dispatch(checkFailed(error));
  }
};
