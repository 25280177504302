import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  progressContainer: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Loader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.progressContainer}>
      <CircularProgress color="secondary" />
    </div>
  );
};

export default Loader;
