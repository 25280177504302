import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import rwdApi from 'services/rwdApi';
import { AppThunk } from 'store';
import { Memorial, Memory } from 'types';

type MemorialState = {
  data: Memorial | null;
  fetching: boolean;
  saving: boolean;
  error?: string;
  emailValidated?: boolean;
  memorySaving: boolean;
  deleting: boolean;
};

const initialState: MemorialState = {
  data: null,
  fetching: false,
  saving: false,
  memorySaving: false,
  deleting: false,
};

const memorial = createSlice({
  name: 'memorial',
  initialState,
  reducers: {
    fetchMemorialStart: (state) => {
      state.error = undefined;
      state.fetching = true;
    },
    fetchMemorialError: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.data = null;
    },
    setMemorial: (state, { payload }) => {
      state.fetching = false;
      state.saving = false;
      state.data = payload;
    },
    setPrivateMemorial: (state, { payload }) => {
      state.fetching = false;
      state.saving = false;
      state.data = payload;
      state.emailValidated = true;
    },
    saveMemorialStart: (state) => {
      state.error = undefined;
      state.saving = true;
    },
    saveMemorialError: (state, { payload }) => {
      state.error = payload;
      state.saving = false;
      state.data = null;
    },
    saveMemoryStart: (state) => {
      state.error = undefined;
      state.memorySaving = true;
    },
    saveMemoryError: (state, { payload }) => {
      state.error = payload;
      state.memorySaving = false;
      state.data = null;
    },
    updateMemorySuccess: (state, { payload }) => {
      const index = findIndex(
        state.data?.memories,
        (memory) => memory.uid === payload.uid
      );

      state.error = undefined;
      state.memorySaving = false;
      if (state.data) state.data.memories[index] = payload;
    },
    createMemorySuccess: (state, { payload }) => {
      state.error = undefined;
      state.memorySaving = false;
      if (state.data) state.data.memories = [...state.data.memories, payload];
    },
    deleteMemoryStart: (state) => {
      state.error = undefined;
      state.deleting = true;
    },
    deleteMemorySuccess: (state, { payload }) => {
      state.error = undefined;
      state.deleting = false;

      if (state.data) {
        state.data.memories = state.data.memories.filter(
          (memory) => memory.uid !== payload
        );
      }
    },
  },
});

export const {
  setMemorial,
  setPrivateMemorial,
  fetchMemorialError,
  fetchMemorialStart,
  saveMemorialStart,
  saveMemorialError,
  saveMemoryStart,
  saveMemoryError,
  updateMemorySuccess,
  createMemorySuccess,
  deleteMemoryStart,
  deleteMemorySuccess,
} = memorial.actions;

export default memorial.reducer;

export const fetchMemorial = (id: string): AppThunk => async (dispatch) => {
  dispatch(fetchMemorialStart());
  const { status, message, memorialPage } = await rwdApi.getPublicMemorial(id);

  if (status === 'ok') {
    dispatch(setMemorial(memorialPage));
  } else {
    dispatch(fetchMemorialError(message));
  }
};

export const fetchPrivateMemorial = (
  id: string,
  email: string
): AppThunk => async (dispatch) => {
  dispatch(fetchMemorialStart());
  const { status, message, memorialPage } = await rwdApi.getPrivateMemorial(
    id,
    email
  );

  if (status === 'ok') {
    dispatch(setPrivateMemorial(memorialPage));
  } else {
    dispatch(fetchMemorialError(message));
  }
};

export const fetchUserMemorial = (): AppThunk => async (dispatch) => {
  dispatch(fetchMemorialStart());
  const { status, message, memorialPages } = await rwdApi.getUserMemorials();

  if (status === 'ok') {
    dispatch(setMemorial(memorialPages[0]));
  } else {
    dispatch(fetchMemorialError(message));
  }
};

type MemorialFormBody = Partial<Memorial> & {
  uid?: string;
};

export const saveMemorialPage = (
  memorial: MemorialFormBody,
  reload = true
): AppThunk => async (dispatch) => {
  dispatch(saveMemorialStart());
  let response;
  if (memorial.uid) {
    response = await rwdApi.updateMemorialPage(memorial);
  } else {
    response = await rwdApi.createMemorialPage(memorial);
    const { memorialPage } = response;
    const memoryRequests: unknown[] = [];
    memorial.memories?.forEach((memory) => {
      memoryRequests.push(rwdApi.createMemory(memorialPage.uid, memory));
    });

    await Promise.all(memoryRequests);
  }

  const { status, message, memorialPage } = response;

  if (status === 'ok' || status === 'created') {
    dispatch(setMemorial(memorialPage));

    // force refresh page
    if (reload) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  } else {
    dispatch(saveMemorialError(message));
  }
};

export const saveMemory = (
  memorialUid: string,
  memory: Partial<Memory>
): AppThunk => async (dispatch) => {
  dispatch(saveMemoryStart());
  let response;
  if (memory.uid) {
    response = await rwdApi.updateMemory(memorialUid, memory as Memory);
    dispatch(updateMemorySuccess(response.memory));
  } else {
    response = await rwdApi.createMemory(memorialUid, memory as Memory);
    dispatch(createMemorySuccess(response.memory));
  }
};

export const deleteMemory = (
  memorialUid: string,
  memory: Partial<Memory>
): AppThunk => async (dispatch) => {
  dispatch(deleteMemoryStart());

  await rwdApi.deleteMemory(memorialUid, memory as Memory);

  dispatch(deleteMemorySuccess(memory.uid));
};
