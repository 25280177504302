import { createSlice } from '@reduxjs/toolkit';
import { Memorial } from 'types';

type EditState = {
  themeType: number;
  editMode: boolean;
  memorial?: Partial<Memorial>;
};

const initialState: EditState = {
  themeType: 1,
  editMode: false,
};

const memorial = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    setEditMode: (state, { payload }) => {
      state.editMode = payload;
    },
    setEditMemorial: (state, { payload }) => {
      state.memorial = payload;
    },
    clearEditMemorial: (state) => {
      state.memorial = undefined;
    },
    setThemeType: (state, { payload }) => {
      state.themeType = payload;
    },
  },
});

export const {
  setEditMode,
  setEditMemorial,
  clearEditMemorial,
  setThemeType,
} = memorial.actions;

export default memorial.reducer;
