import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export type HttpResponse = {
  status: string | number;
  message: string;
  // data?: T;
};

abstract class HttpClient {
  protected readonly axios: AxiosInstance;

  public constructor(baseURL: string) {
    this.axios = axios.create({
      baseURL,
    });

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.axios.interceptors.request.use(this._handleRequest, this._handleError);
  };

  private _initializeResponseInterceptor = () => {
    this.axios.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    const newConfig = { ...config };
    if (newConfig.headers['Content-Type'] === 'multipart/form-data')
      return newConfig;

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params);
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
  };

  private _handleResponse = (response: AxiosResponse) => {
    // Capture no content
    if (response.status === 204) {
      response.data = {
        status: 'ok',
      };
    }

    if (response.data) {
      response.data = camelizeKeys(response.data);
    }

    return response.data;
  };

  protected _handleError = (error: AxiosError): HttpResponse => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);

      return { ...error.response.data, status: error.response.status };
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        status: 503,
        message: 'Network Error. Please try again.',
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        status: 500,
        message: error.message,
      };
    }
  };
}

export default HttpClient;
