import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { ImageObject } from 'types';
import rwdApi from 'services/rwdApi';
import { fetchMemorialError } from './memorial';

type UploadsState = {
  open: boolean;
  fieldName?: string;
  list: ImageObject[];
  fetching: boolean;
  uploading: boolean;
  deleting: boolean;
  uploaded?: Partial<ImageObject> & { fieldName?: string };
  error?: string;
};

const initialState: UploadsState = {
  open: false,
  list: [],
  fetching: false,
  uploading: false,
  deleting: false,
};

const uploads = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.open = true;
      state.fieldName = payload;
    },
    closeModal: (state) => {
      state.open = false;
      state.fieldName = undefined;
    },
    fetchImagesStart: (state) => {
      state.error = undefined;
      state.fetching = true;
    },
    fetchImagesError: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.list = [];
    },
    setImagesList: (state, { payload }) => {
      state.fetching = false;
      state.list = payload;
    },
    uploadImageStart: (state) => {
      state.error = undefined;
      state.uploading = true;
      state.uploaded = undefined;
    },
    uploadImageError: (state, { payload }) => {
      state.error = payload;
      state.uploading = false;
      state.list = [];
      state.uploaded = undefined;
    },
    uploadImageSuccess: (state, { payload }) => {
      state.uploaded = { ...payload, fieldName: state.fieldName };
      state.open = false;
      state.error = undefined;
      state.uploading = false;
      state.fieldName = undefined;
    },
    deleteImageStart: (state) => {
      state.deleting = true;
    },
    deleteImageSuccess: (state, { payload }) => {
      state.list = state.list.filter((l) => l.uid !== payload);
      state.deleting = false;
      state.error = undefined;
    },
    deleteImageFailed: (state, { payload }) => {
      state.deleting = false;
      state.error = payload;
    },
  },
});

export const {
  openModal,
  closeModal,
  fetchImagesStart,
  fetchImagesError,
  setImagesList,
  uploadImageStart,
  uploadImageError,
  uploadImageSuccess,
  deleteImageStart,
  deleteImageSuccess,
  deleteImageFailed,
} = uploads.actions;

export default uploads.reducer;

export const uploadImage = (image: File): AppThunk => async (dispatch) => {
  dispatch(uploadImageStart());

  const { status, message, image: uploaded } = await rwdApi.uploadImage(image);

  if (status === 'ok') {
    dispatch(uploadImageSuccess(uploaded));
  } else {
    dispatch(uploadImageError(message));
  }
};

export const fetchUserImages = (): AppThunk => async (dispatch) => {
  dispatch(fetchImagesStart());

  const { status, message, images } = await rwdApi.fetchUserImages();

  if (status === 'ok') {
    dispatch(setImagesList(images));
  } else {
    dispatch(fetchMemorialError(message));
  }
};

export const deleteImage = (id: string): AppThunk => async (dispatch) => {
  dispatch(deleteImageStart());

  const { status } = await rwdApi.deleteImage(id);

  if (status === 'ok') {
    dispatch(deleteImageSuccess(id));
  } else {
    dispatch(deleteImageFailed(status));
  }
};
