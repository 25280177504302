import { createSlice } from '@reduxjs/toolkit';
import rwdApi from 'services/rwdApi';
import { AppThunk } from 'store';
import { PurchaseRequestBody } from 'types';

type PurchaseState = {
  sessionId?: string;
  pricePlanId?: string;
  fetchingSessionId: boolean;
  ongoingSession: boolean;
  error?: string;
};

const initialState: PurchaseState = {
  fetchingSessionId: false,
  ongoingSession: false,
};

const purchase = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    purchaseStart: (state, { payload }) => {
      state.fetchingSessionId = true;
      state.ongoingSession = false;
      state.error = undefined;
      state.pricePlanId = payload;
    },
    purchaseFailed: (state, { payload }) => {
      state.fetchingSessionId = false;
      state.ongoingSession = false;
      state.error = payload;
      state.sessionId = undefined;
      state.pricePlanId = undefined;
    },
    purchaseSuccess: (state, { payload }) => {
      state.fetchingSessionId = false;
      state.ongoingSession = true;
      state.error = undefined;
      state.sessionId = payload.sessionId;
      state.pricePlanId = payload.pricePlanId;
    },
  },
});

export const {
  purchaseStart,
  purchaseFailed,
  purchaseSuccess,
} = purchase.actions;

export default purchase.reducer;

export const purchaseRequest = ({
  pricePlanId,
  successUrl,
  cancelUrl,
  couponId,
}: PurchaseRequestBody): AppThunk => async (dispatch) => {
  dispatch(purchaseStart(pricePlanId));

  const { status, message, sessionId } = await rwdApi.buy({
    pricePlanId,
    successUrl,
    cancelUrl,
    couponId,
  });

  if (status === 'ok') {
    dispatch(purchaseSuccess({ sessionId, pricePlanId }));
  } else {
    dispatch(purchaseFailed(message));
  }
};
