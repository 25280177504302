import { createSlice } from '@reduxjs/toolkit';
import { LoginFormBody, SocialLoginBody } from 'types';
import { AppThunk } from 'store';
import api from 'services/rwdApi';

type AuthState = {
  accessKey: string | null;
  loading: boolean;
  error: string | null;
};

const initialState: AuthState = {
  accessKey: null,
  loading: false,
  error: null,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, { payload }) => {
      state.accessKey = payload;
      state.loading = false;
      state.error = null;
    },
    loginFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    signUpStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    signUpSuccess: (state, { payload }) => {
      state.accessKey = payload;
      state.loading = false;
      state.error = null;
    },
    signUpFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    logout: () => initialState,
  },
});

export const {
  loginStart,
  loginFailed,
  loginSuccess,
  signUpStart,
  signUpSuccess,
  signUpFailed,
  logout,
} = auth.actions;

export const loginRequest = ({
  email,
  password,
}: LoginFormBody): AppThunk => async (dispatch) => {
  dispatch(loginStart());
  const { status, message, accessKey } = await api.loginWithEmail({
    email,
    password,
  });

  if (status === 'ok') {
    dispatch(loginSuccess(accessKey));
  } else {
    dispatch(loginFailed(message));
  }
};

export const socialLoginRequest = ({
  credentials,
  provider,
}: SocialLoginBody): AppThunk => async (dispatch) => {
  dispatch(loginStart());
  const { status, message, accessKey } = await api.socialLogin({
    credentials,
    provider,
  });

  if (status === 'ok') {
    dispatch(loginSuccess(accessKey));
  } else {
    dispatch(loginFailed(message));
  }
};

export const signUpRequest = ({
  email,
  password,
}: LoginFormBody): AppThunk => async (dispatch) => {
  dispatch(signUpStart());
  const { status, message, accessKey } = await api.signUpWithEmail({
    email,
    password,
  });

  if (status === 'created') {
    dispatch(signUpSuccess(accessKey));
  } else {
    dispatch(signUpFailed(message));
  }
};

export default auth.reducer;
