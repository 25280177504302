import { AxiosRequestConfig } from 'axios';
import HttpClient, { HttpResponse } from './httpClient';
import { apiURL } from 'config';
import {
  CondolenceFormBody,
  ImageObject,
  LoginFormBody,
  Memorial,
  Memory,
  PurchaseRequestBody,
  SocialLoginBody,
  User,
} from 'types';

type MemorialPageResponse = HttpResponse & {
  memorialPage: Memorial;
};

type AuthResponse = HttpResponse & {
  accessKey: string;
  user: User;
};

type UserMemorialPagesResponse = HttpResponse & {
  memorialPages: Memorial[];
};

type ImageResponse = HttpResponse & {
  image: ImageObject;
};

type ImageListResponse = HttpResponse & {
  images: ImageObject[];
};

type UserDetailsResponse = HttpResponse & {
  user: User;
};

type MemoryResponse = HttpResponse & {
  memory: Memory;
};

class RwdAPI extends HttpClient {
  private static _instance?: RwdAPI;

  public constructor() {
    super(apiURL);
  }

  public updateRequestInterceptor = (token: string) => {
    this.axios.interceptors.request.use((config: AxiosRequestConfig) => {
      const newConfig = { ...config };
      newConfig.headers['Authorization'] = `Bearer ${token}`;

      return newConfig;
    }, this._handleError);
  };

  public static get instance() {
    if (!this._instance) {
      this._instance = new RwdAPI();
    }

    return this._instance;
  }

  public get axiosInstance() {
    return this.axios;
  }

  /** Public */
  public createCondolence = ({
    memorialUid,
    name,
    content,
    longContent,
    image,
  }: CondolenceFormBody): Promise<HttpResponse> => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('content', content);
    formData.append('long_content', longContent);
    if (image) {
      formData.append('image', image);
    }

    return this.axios.post(
      `/publics/memorial_pages/${memorialUid}/v2/condolences`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
  };

  public checkAvailability = (
    pageLink: string
  ): Promise<
    HttpResponse & {
      availability: boolean;
    }
  > =>
    this.axios.post('/publics/memorial_pages/check_availablity/', { pageLink });

  public validateDiscountCode = (couponId: string): Promise<HttpResponse> =>
    this.axios.get(`/users/transactions/coupons/${couponId}`);

  /** User Auth */
  public loginWithEmail = ({
    email,
    password,
  }: LoginFormBody): Promise<AuthResponse> =>
    this.axios.post('/users/authentications/sign_in', {
      email,
      password,
    });

  public signUpWithEmail = ({
    email,
    password,
  }: LoginFormBody): Promise<AuthResponse> =>
    this.axios.post('/users/authentications/sign_up', {
      email,
      password,
    });

  public socialLogin = ({
    credentials,
    provider,
  }: SocialLoginBody): Promise<AuthResponse> => {
    const authEndpoint = `/users/authentications/${
      provider === 'facebook' ? 'facebook_sign_in' : 'google_sign_in'
    }`;

    return this.axios.post(authEndpoint, { ...credentials });
  };

  /** User Details */
  public getUserDetails = (): Promise<UserDetailsResponse> =>
    this.axios.get('/users/users/me');

  /** Memorial Pages */
  public getPublicMemorial = (id: string): Promise<MemorialPageResponse> => {
    return this.axios.get(`/publics/memorial_pages/name/${id}`);
  };

  public getPrivateMemorial = (
    id: string,
    email: string
  ): Promise<MemorialPageResponse> => {
    return this.axios.post(`/publics/memorial_pages/name/${id}`, { email });
  };

  public getUserMemorials = (): Promise<UserMemorialPagesResponse> => {
    return this.axios.get(`/users/memorial_pages/`);
  };

  public createMemorialPage = (
    memorial: Partial<Memorial>
  ): Promise<MemorialPageResponse> => {
    return this.axios.post(`/users/memorial_pages`, {
      ...memorial,
    });
  };

  public updateMemorialPage = (
    memorial: Partial<Memorial> & {
      uid?: string;
    }
  ): Promise<MemorialPageResponse> => {
    return this.axios.put(`/users/memorial_pages/${memorial.uid}`, {
      ...memorial,
    });
  };

  // Images
  public fetchUserImages = (): Promise<ImageListResponse> => {
    return this.axios.get(`/users/images`);
  };

  public uploadImage = (image: File): Promise<ImageResponse> => {
    const formData = new FormData();
    formData.append('background_image_v2', image);

    return this.axios.post(`/users/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  public deleteImage = (id: string): Promise<HttpResponse> => {
    return this.axios.delete(`/users/images/${id}`);
  };

  // Purchase
  public buy = ({
    successUrl,
    cancelUrl,
    pricePlanId,
  }: PurchaseRequestBody): Promise<HttpResponse & { sessionId: string }> => {
    return this.axios.post(`/users/transactions/buy`, {
      successUrl,
      cancelUrl,
      pricePlanId,
    });
  };

  // Condolences Management
  public deleteCondolence = ({
    memorialUid,
    condolenceUid,
  }: {
    memorialUid: string;
    condolenceUid: string;
  }): Promise<HttpResponse> =>
    this.axios.delete(
      `/users/memorial_pages/${memorialUid}/condolences/${condolenceUid}`
    );

  public approveCondolence = ({
    memorialUid,
    condolenceUid,
  }: {
    memorialUid: string;
    condolenceUid: string;
  }): Promise<HttpResponse> =>
    this.axios.post(
      `/users/memorial_pages/${memorialUid}/condolences/${condolenceUid}/approve`
    );

  // Invites
  public sendInvites = (
    memorialUid: string,
    data: string[]
  ): Promise<HttpResponse> =>
    this.axios.post(`/users/memorial_pages/${memorialUid}/invite`, { data });

  // Memories
  public createMemory = (
    memorialUid: string,
    memory: Memory
  ): Promise<MemoryResponse> =>
    this.axios.post(`/users/memorial_pages/${memorialUid}/memories`, {
      ...memory,
      title: 'memory',
    });

  public updateMemory = (
    memorialUid: string,
    memory: Memory
  ): Promise<MemoryResponse> =>
    this.axios.put(
      `/users/memorial_pages/${memorialUid}/memories/${memory.uid}`,
      { ...memory, title: 'memory' }
    );

  public deleteMemory = (
    memorialUid: string,
    memory: Memory
  ): Promise<HttpResponse> =>
    this.axios.delete(
      `/users/memorial_pages/${memorialUid}/memories/${memory.uid}`
    );
}

export default RwdAPI.instance;
