import { createSlice } from '@reduxjs/toolkit';
import rwdApi from 'services/rwdApi';
import { AppThunk } from 'store';
import { CondolenceFormBody } from 'types';

type CondolenceState = {
  saving: boolean;
  deleting: boolean;
  error?: string;
};

const initialState: CondolenceState = {
  saving: false,
  deleting: false,
};

const condolence = createSlice({
  name: 'condolence',
  initialState,
  reducers: {
    saveCondolenceStart: (state) => {
      state.saving = true;
      state.error = undefined;
    },
    saveCondolenceFailed: (state, { payload }) => {
      state.saving = false;
      state.error = payload;
    },
    saveCondolenceSuccess: (state) => {
      state.saving = false;
      state.error = undefined;
    },
    deleteCondolenceStart: (state) => {
      state.deleting = true;
      state.error = undefined;
    },
    deleteCondolenceFailed: (state, { payload }) => {
      state.deleting = false;
      state.error = payload;
    },
    deleteCondolenceSuccess: (state) => {
      state.deleting = false;
      state.error = undefined;
    },
  },
});

export const {
  saveCondolenceStart,
  saveCondolenceFailed,
  saveCondolenceSuccess,
  deleteCondolenceStart,
  deleteCondolenceFailed,
  deleteCondolenceSuccess,
} = condolence.actions;

export default condolence.reducer;

export const createCondolence = (body: CondolenceFormBody): AppThunk => async (
  dispatch
) => {
  dispatch(saveCondolenceStart());
  const { status, message } = await rwdApi.createCondolence(body);

  if (status === 'created') {
    dispatch(saveCondolenceSuccess());
  } else {
    dispatch(saveCondolenceFailed(message));
  }
};

export const deleteCondolence = ({
  memorialUid,
  condolenceUid,
}: {
  memorialUid: string;
  condolenceUid: string;
}): AppThunk => async (dispatch) => {
  dispatch(deleteCondolenceStart());
  const { status, message } = await rwdApi.deleteCondolence({
    memorialUid,
    condolenceUid,
  });

  if (status === 'ok') {
    dispatch(deleteCondolenceSuccess());
  } else {
    dispatch(deleteCondolenceFailed(message));
  }
};

export const approveCondolence = ({
  memorialUid,
  condolenceUid,
}: {
  memorialUid: string;
  condolenceUid: string;
}): AppThunk => async (dispatch) => {
  dispatch(saveCondolenceStart());
  const { status, message } = await rwdApi.approveCondolence({
    memorialUid,
    condolenceUid,
  });

  if (status === 'ok') {
    dispatch(saveCondolenceSuccess());
  } else {
    dispatch(deleteCondolenceFailed(message));
  }
};
