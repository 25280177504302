import Loader from 'components/Loader';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

const PublicRoutes = React.lazy(() => import('./PublicRoutes'));
const DashboardRoutes = React.lazy(() => import('./DashboardRoutes'));

const Home = React.lazy(() => import('../pages/Home'));
const About = React.lazy(() => import('../pages/About'));
const FAQS = React.lazy(() => import('../pages/FAQS'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Demos = React.lazy(() => import('../pages/Demos'));
const Pricing = React.lazy(() => import('../pages/Pricing'));
const Account = React.lazy(() => import('../pages/Account'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
const MemorialPage = React.lazy(() => import('../pages/MemorialPage'));
const EditPage = React.lazy(() => import('../pages/EditPage'));
const RedirectPage = React.lazy(() => import('../pages/Redirect'));
const PaymentSuccess = React.lazy(() => import('../pages/PaymentSuccess'));
const Terms = React.lazy(() => import('../pages/Terms'));

const App = (): React.ReactElement => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoutes exact path="/" component={Home} />
          <PublicRoutes exact path="/about" component={About} />
          <PublicRoutes path="/demos" component={Demos} noAppBar />
          <PublicRoutes exact path="/faqs" component={FAQS} />
          <PublicRoutes exact path="/pricing" component={Pricing} />
          {/** TODO: /account/login and /account/signup */}
          <PublicRoutes path="/account" component={Account} />
          <DashboardRoutes path="/dashboard" component={Dashboard} />
          <PublicRoutes exact path="/edit" component={EditPage} />
          <PublicRoutes
            exact
            path="/payment_success"
            component={PaymentSuccess}
          />
          <PublicRoutes exact path="/terms" component={Terms} />
          <PublicRoutes path="/redirect" component={RedirectPage} noAppBar />
          <PublicRoutes exact path="/not-found" component={NotFound} />
          <PublicRoutes path="/:id" component={MemorialPage} noAppBar />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
